import { readFileSync } from 'fs';
import EditorLayout, {
  CodeEditor,
  PanePage,
  PaneEditor,
  Iframe,
} from '../lib/components/editor';
import React, { useEffect, useRef, useState } from 'react';
import { useRouter } from 'next/router';
import { gql, useQuery } from '@apollo/client';
// import { context as contextResolver } from '../../lib/graphql/context';
// import { initializeApollo } from '../../lib/graphql/apollo';
import { Meta, Spinner } from '../lib/ui';
import { useAuth } from '../lib/auth';
import { Save, Download, Share, Send, DarkTheme } from '../lib/components/editor/actions';
import starterCss from '../lib/landing-page-templates/css';
import starterHtml from '../lib/landing-page-templates/html';

type Props = {
  starterHtml: string;
  starterCss: string;
};

const isServer = typeof window === 'undefined';

// TODO: store template in localstorage
const LandingPage = (_props: Props) => {
  const router = useRouter();
  const { user } = useAuth();
  const htmlRef = useRef(null);
  const cssRef = useRef(null);
  const saveButton = useRef(null);
  const [htmlVal, setHtml] = useState(starterHtml);
  const [cssVal, setCss] = useState(starterCss);
  const [hasUnsavedChanges, setHasUnsavedChanges] = useState(false);
  const [isHtmlLoaded, setHtmlLoaded] = useState(false);
  const [isCssLoaded, setCssLoaded] = useState(false);
  const [isInitValueSet, setInitValue] = useState(false);
  const [darkTheme ,setDarkTheme] = useState(false)

  useEffect(() => {
    if (!isInitValueSet && !user) {
      setInitValue(true);
    }
  }, [isInitValueSet, user]);
  // has changes
  useEffect(() => {
    if (starterHtml === htmlVal && starterCss === cssVal) {
      return setHasUnsavedChanges(false);
    }
    if (!hasUnsavedChanges && isInitValueSet) {
      setHasUnsavedChanges(true);
    }
  }, [
    starterCss,
    starterHtml,
    cssVal,
    htmlVal,
    hasUnsavedChanges,
    isInitValueSet,
  ]);
  useEffect(() => {
    const confirmationMessage =
      'The last changes you made to this template have not been saved. Are you sure you want to navigate away?';
    const beforeUnloadHandler = (e: BeforeUnloadEvent) => {
      (e || window.event).returnValue = confirmationMessage;
      return confirmationMessage; // Gecko + Webkit, Safari, Chrome etc.
    };
    const beforeRouteHandler = (url: string) => {
      if (router.pathname !== url && !confirm(confirmationMessage)) {
        // to inform NProgress or something ...
        router.events.emit('routeChangeError');
        // tslint:disable-next-line: no-string-throw
        throw 'ROUTE_CHANGE_ERROR';
      }
    };
    if (hasUnsavedChanges) {
      window.addEventListener('beforeunload', beforeUnloadHandler);
      router.events.on('routeChangeStart', beforeRouteHandler);
    } else {
      window.removeEventListener('beforeunload', beforeUnloadHandler);
      router.events.off('routeChangeStart', beforeRouteHandler);
    }
    return () => {
      window.removeEventListener('beforeunload', beforeUnloadHandler);
      router.events.off('routeChangeStart', beforeRouteHandler);
    };
  }, [hasUnsavedChanges]);
  function onHtmlMount(editor, monaco) {
    htmlRef.current = editor;
    editor.addCommand(monaco.KeyMod.CtrlCmd | monaco.KeyCode.KEY_S, () => {
      if (saveButton.current) {
        saveButton.current.click();
      }
    });
    setHtmlLoaded(true);
  }
  function onCssMount(editor, monaco) {
    cssRef.current = editor;
    editor.addCommand(monaco.KeyMod.CtrlCmd | monaco.KeyCode.KEY_S, () => {
      if (saveButton.current) {
        saveButton.current.click();
      }
    });
    setCssLoaded(true);
  }

  const save = (
    <Save
      buttonRef={saveButton}
      hasChanges={hasUnsavedChanges}
      data={{ rawTemplate: htmlVal, css: cssVal, id: 'starter' }}
    />
  );
  const download = (
    <Download
      data={{
        rawTemplate: htmlVal,
        css: cssVal,
        name: 'Postdrop',
      }}
    />
  );

  const darkModeSetting = <DarkTheme darkTheme={darkTheme} setDarkTheme={setDarkTheme} isPublic={true} planData={undefined} key={"dark-theme"}/>

  const share = <Share data={{ id: 'starter' }} />;
  const send = (
    <Send data={{ id: 'starter', rawTemplate: htmlVal, css: cssVal }} />
  );
  if (user) {
    router.push('/templates');
    return null;
  }
  return (
    <EditorLayout
      subtitle={`Inline, send and test HTML email templates`}
      save={save}
      download={download}
      share={share}
      darkMode={darkModeSetting}
      send={send}>
      <Meta title={`Inline, send and test HTML email templates`} />
      <PanePage>
        <PaneEditor>
          <CodeEditor
            onMount={onHtmlMount}
            value={htmlVal}
            onChange={setHtml}
            language="html"
            loaded={isHtmlLoaded}
          />
          <CodeEditor
            onMount={onCssMount}
            value={cssVal}
            onChange={setCss}
            language="css"
            loaded={isCssLoaded}
          />
        </PaneEditor>
        <div className="bg-white flex-1 h-full w-full flex flex-col">
          <Iframe
            src={htmlVal}
            css={cssVal}
            sandbox="allow-popups allow-popups-to-escape-sandbox"
            className="bg-white flex-1 h-full w-full"
          />
        </div>
      </PanePage>
    </EditorLayout>
  );
};

export default LandingPage;
